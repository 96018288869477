import { useEffect, Suspense, lazy } from 'react'
import './style/color.scss'
import './style/fonts.scss'
import './App.scss'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import ContentfulProvider from './context/ContentfulProvider/ContentfulProvider'

const Page = lazy(() => import('./pages/Page/Page'))
const App = () => {
  const history = useHistory()
  const location = useLocation()
  const originalUri = async (auth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <ContentfulProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/:slug" component={Page} />
            <Route path="*" component={Page} />
          </Switch>
        </Suspense>
    </ContentfulProvider>
  )
}

export default App
